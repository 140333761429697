import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ComplianceRequirementDetailsDTO,
  ComplianceRequirementFormDTO,
  ComplinaceRequirementListResponseDTO,
} from '../dto/compliance-requirement.dto';
import { SubCRUDServiceInterface } from '../../../../core/interfaces/service/sub-crud-service.interface';
import { buildParams } from '../../../../core/modals/table.modal';

@Injectable({
  providedIn: 'root',
})
export class ComplianceRequirementService implements SubCRUDServiceInterface {
  protected apiUrl = environment.apiUrl + '/compliances';

  constructor(protected http: HttpClient) {}

  list(
    complianceId: number,
    sortField: string = '',
    sortOrder: string = 'asc',
    currentPage: number = 1,
    pageSize: number = 10,
    searchQuery?: string,
    quickFilterKey?: string,
    dynamicFilters?: { [key: string]: number[] }
  ): Observable<ComplinaceRequirementListResponseDTO> {
    const params = buildParams(
      sortField,
      sortOrder,
      currentPage,
      pageSize,
      searchQuery,
      quickFilterKey,
      dynamicFilters
    );

    return this.http.get<ComplinaceRequirementListResponseDTO>(
      `${this.apiUrl}/${complianceId}/requirements`,
      { params }
    );
  }

  get(
    complianceId: number,
    requirementId: number
  ): Observable<ComplianceRequirementDetailsDTO> {
    return this.http.get<ComplianceRequirementDetailsDTO>(
      `${this.apiUrl}/${complianceId}/requirements/${requirementId}`
    );
  }

  create(
    complianceId: number,
    item: ComplianceRequirementFormDTO
  ): Observable<ComplianceRequirementFormDTO> {
    return this.http.post<ComplianceRequirementFormDTO>(
      `${this.apiUrl}/${complianceId}/requirements`,
      item
    );
  }

  update(
    complianceId: number,
    requirementId: number,
    item: ComplianceRequirementFormDTO
  ): Observable<ComplianceRequirementFormDTO> {
    return this.http.put<ComplianceRequirementFormDTO>(
      `${this.apiUrl}/${complianceId}/requirements/${requirementId}`,
      item
    );
  }
}
